<template src="./EditProfile.html"></template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/Profile";
import locationService from "@/services/Locations";
import loginService from "@/services/Login";
import EditProfileLoadPhotoModal from "./EditProfileLoadPhotoModal/EditProfileLoadPhotoModal";
import { genderTransform } from "@/filters/gender";
import ArrobaIdIcon from "@/components/Icons/ArrobaIdIcon";
import { required, sameAs, minLength, url } from "vuelidate/lib/validators";
import { PROFILE_IMAGEN } from "@/utils/constants";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import tutorial from "@/mixins/tutorial";
import StatesByCountry from "@/components/StatesByCountry/StatesByCountry";
import CitiesByState from "@/components/CitiesByState/CitiesByState";
import { putFile } from "@/services/files";

export default {
  name: "edit-profile",
  mixins: [tutorial],
  filters: { genderTransform },
  components: {
    Cropper,
    ArrobaIdIcon,
    EditProfileLoadPhotoModal,
    StatesByCountry,
    CitiesByState,
  },
  data() {
    return {
      img: "",
      password: "",
      password_verify: "",
      editField: [],
      curriculumVitae: null,
      openModal: false,
      photoFile: null,
      urlPhotoL: null,
      PROFILE_IMAGEN,
      countriesOptions: [],
      citiesOptions: [],
      getStates: [],
      getCities: [],
      event: "",
      passwordActive: false,
      countries: [],
      role: "",
    };
  },
  validations() {
    const password = {
      required,
      minLength: minLength(6),
      containsPasswordRequirement: (value) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value),
    };
    const password_verify = {
      sameAsPassword: sameAs("password"),
    };

    let validations = {
      user: {
        socialMedia: {
          linkedin: { url },
          behance: { url },
          researchgate: { url },
          orci: { url },
          github: { url },
          dribble: { url },
        },
      },
    };

    if (this.passwordActive) {
      validations.password = password;
      validations.password_verify = password_verify;
    }

    return validations;
  },
  created() {
    this.reloadUser();
    this.role = this.$store.state.User.user.role.name;
  },
  mounted() {
    this.getCountries();
    this.viewTutorial();
  },
  updated() {
    if (this.curriculumVitae) {
      this.$buefy.toast.open({
        duration: 4000,
        message:
          "Hoja de vida adjunta. Para completar el proceso Guarda cambios en esta sección.",
        position: "is-bottom",
        type: "is-success",
      });
    }
  },
  methods: {
    async reloadUser() {
      try {
        const user = await profileService.getUserInfo();
        if (!user.socialMedia) user.socialMedia = {};
        this.$store.dispatch("fetchUser", user);
      } catch (error) {
        console.warn("No sesion for User");
        this.$router.push("/login");
      }
    },
    async editProfile() {
      try {
        this.$store.dispatch("fetchLoading", true);
        if (this.photoFile) {
          this.user.photo = await profileService.putFile(
            "users",
            "profilePhoto",
            this.photoFile.name,
            this.photoFile,
            this.user.id
          );
        }

        if (this.curriculumVitae) {
          this.user.cvUrl = await profileService.putFile(
            "users",
            "cv",
            this.curriculumVitae.name,
            this.curriculumVitae,
            this.user.id
          );
        }

        if (
          this.password_verify &&
          this.password &&
          this.password_verify === this.password
        ) {
          await loginService.passwordRecoveryUserController(
            {
              password: this.password,
              password_verify: this.password_verify,
            },
            this.$store.state.User.tokenControllerPhp
          );
        }
        await profileService.updateUserInfo(this.user);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Tus datos han sido actualizados con éxito.",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({ path: "/perfil" });
      } catch (error) {
        console.error("Error updating user profile", error);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Ocurrio un error, intenta mas tarde.",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    toggleEditField(field) {
      if (field === "password") this.passwordActive = !this.passwordActive;
      if (field === "pais") {
        this.user.state = null;
        this.user.city = null;
      }
      if (field === "departamento") this.user.city = null;

      if (this.editField.some((ef) => field === ef)) {
        const index = this.editField.findIndex((ef) => ef === field);
        this.editField.splice(index, 1);
      } else {
        this.editField.push(field);
      }
    },
    dataURLtoFile(dataurl) {
      //Convert base64 to file
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `profile_pic_${this.user.id}`, {
        type: mime,
      });
    },
    loadFile(photo) {
      this.photoFile = this.dataURLtoFile(photo);
      this.urlPhotoL = URL.createObjectURL(this.photoFile);
      this.openModal = false;
    },
    closeModal() {
      this.openModal = false;
    },
    async getCountries() {
      try {
        this.countries = await locationService.getCountries();
      } catch (e) {
        console.error("error", e);
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    viewTutorial() {
      this.dataTutorial("editProfile");
    },
    async uploadCoverPhoto(coverPhoto) {
      try {
        this.$store.dispatch("fetchLoading", true);
        const urlImage = await putFile(
          coverPhoto,
          "coverPhoto",
          `${coverPhoto.name}`
        );
        this.user.banner = urlImage;
        await profileService.updateUserInfo(this.user);
        this.openModal = false;
        this.$store.dispatch("fetchLoading", false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Archivo cargado con éxito.",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error cargando el archivo",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    userPhoto() {
      if (this.urlPhotoL) return this.urlPhotoL;
      if (this.user && this.user.photo) return this.user.photo;
      return this.PROFILE_IMAGEN;
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./EditProfile.scss"></style>
